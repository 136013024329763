<template>
	<div class="cs-block cs-blog-post-archive" :class="styleClass">
		<div class="cs-block-base">
			<div class="container">
				<CSBlogPostArchive :data="data" />
			</div>
		</div>
	</div>
</template>

<script>
    import {computed} from '../libs/common-fn';

	export default {
		name: "BlogPostArchive",
		components: {
			CSBlogPostArchive: () => import("../components/CSBlogPostArchive.vue"),
		},
		props: {
			data: {
				type: Object,
                default: () => {},
			},
		},
		computed: computed('BlogPostArchive')
	}
</script>

<style lang="scss" scoped>
	@import "../styles/main.scss";
	.cs-blog-post-archive {
        &.cs-style-dark ::v-deep {
            .pagination {
                color: #fff !important;
                span svg{
                    fill: #fff !important;
                }
            }
        }
		::v-deep {
            .header-content {
                text-align: center;
                margin-bottom: $header_margin;
            }
            .cs-pc-link {
                border-radius: 0px !important;
                border: none !important;
                h4.cs-pc-title {
                    @include small-title;
                    margin-bottom: 10px !important;
                }
                .cs-pc-text {
                    font-weight: 400 !important;
                    font-size: 16px !important;
                    margin-bottom: 10px !important;
                    span {
                        *:last-child {
                            margin-bottom: 0;
                        }
                    }
                    span.cs-pc-permalink {
                        color: #158fea !important;
                    }
                }
                .cs-pc-tags {

                    span.cs-pc-tag {
                        @include tag-btn;
                    }
                }
                .cs-pc-author {
                    gap: 5px;
                }
            }
            .pagination span {
                background: transparent !important;
                svg {
                    width: 25px;
                    height: 25px;
                    cursor: pointer;
                }
            }
        }
	}
</style>
